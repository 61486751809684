<template>
    <div id="kt_app_footer" class="app-footer">
        <div class="container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
            <!-- <div class="text-dark order-2 order-md-1">
                <span class="text-muted fw-bold me-1">2022©</span>
            </div>
            <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
                <li class="menu-item">
                    <a href="#" target="_blank" class="menu-link px-2">About</a>
                </li>
                <li class="menu-item">
                    <a href="#" target="_blank" class="menu-link px-2">Support</a>
                </li>
                <li class="menu-item">
                    <a href="#" target="_blank" class="menu-link px-2">Purchase</a>
                </li>
            </ul> -->
        </div>
    </div>
</template>